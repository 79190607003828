let lng = "";
export const REGISTRATION_API = `users/signup${lng}`;
export const LOGIN_API = `users/login${lng}`;
export const UPDATE_PASSWORD_API = `users/updateMyPassword`;
export const GET_CURRENT_USER_API = `users/me${lng}`;
export const UPDATE_MY_PROFILE_API = `users/updateMe${lng}`;
export const USER_EMAIL_PREFERNECES_API = `email-preferences${lng}`;
export const USER_EMAIL_PREFERNECES_API_UPDATE = `email-preferences/`;
export const INVESTOR_PROFILE = `investor-profiles`;
export const RESET_PASSWORD = `users/forgotPassword${lng}`;
export const FORGOT_PASSWORD = `users/resetPassword/`;
export const INVESTOR_PROFILE_UPDATE_API = `investor-profiles/`;
export const SITE_SETTINGS = `admin/site-setting/front`;
export const SLIDERS_API = `admin/sliders/front-sliders?status=yes`;
export const GET_LANGUAGES = `admin/languages/front?fields=name,folder&sort=createdAt&status=true`;
export const GET_DEFAULT_LANGUAGE = `admin/languages/front?fields=name,folder&isDefault=true`;
export const RECAPTCHA_API = `admin/recaptcha/front`;
export const MENU_API = `admin/menus/`;
export const CAMPAIGN_TYPES_API = `admin/types/front`;
export const GET_CATEGORIES_API = `admin/categories/front`;
export const GET_INDUSTRY_API = `admin/manage-options/company-industry`;
export const GET_TEAM_MEMBER_API = `admin/manage-options/team-member-type`;
export const GET_CAMPAIGN_TEAM_MEMBERS_API = `equity/team-members/`;
export const COMPANY_DATA_API = `equity/company-profile/`;
export const CAMPAIGN_STAGE_API = `admin/manage-options/campaign-stage`;
export const INVESTING_ROUND_API = `admin/manage-options/investing-round`;
export const INVESTOR_API = `admin/manage-options/investor-type`;
export const FUNDRAISING_API = `campaign/fundraising-detail/`;
export const CONTENT_PAGE_API = `admin/content-page/pages/`;
export const DEAL_TYPE_API = `admin/deal-types/front?status=yes`;
export const CAMPAIGN_LIST_API = `campaigns/equities`;
export const CAMPAIGN_DELETE_API = `campaigns/`;
export const CREATE_CAMPAIGN_TYPE_API = `equities/`;
export const TERMS_API = `campaign/campaign-terms/`;
export const STORY_API = `campaign/campaign-story/`;
export const EXTRAS_API = `campaign/extras-info/`;
export const EXTRAS_IMAGE_API = `equity/extras-images/`;
export const EXTRAS_DOCUMENT_API = `equity/extras-document/`;
export const EXTRAS_VIDEOS_API = `equity/extras-videos/`;
export const PAYMENT_API = `campaign/payment-detail/`;
export const META_API = `admin/meta-setting/front`;
export const EQUITY_API = `equities/`;
export const FAQ_API = `admin/content-page/faqs/front/`;
export const FAQS_API = `equity/campaign-faqs/`;
export const ALL_FRONT_FAQ_API = "/admin/front-faqs/front";
export const EQUITY_UPDATE_API = `equity/updates/`;
export const SAVE_CAMPAIGN = "saved-campaigns/";
export const INVESTMENT_API = `process-investment/`;
export const CONTRACT_API = `contracts/generate-pdf`;
export const CREATE_PDF_API = `contracts/create-pdf`;
export const GET_CONTRACT_API = `/contracts/get-investor-contract`;
export const OFFLINE_API = `admin/payment-gateway/`;
export const STRIPE_API = `equity/payment-gateway/checkout-session/`;
export const CHECK_STRIPE_API = `equity/payment-gateway/check-payment-session/`;
export const TRANSACTIONS_API = `transactions/`;
export const USER_PROFILE_API = `users/profile-network/`;
export const COMMENTS_API = `equity/comments/`;
export const VERIFY_PROFILE_API = `users/activeAccount/`;
export const ACTIVITY_API = `user-activity/`;
export const SEARCH_API = `equities/front`;
export const ACTIVATE_ACCOUNT_API = `users/activeAccount/`;
export const LEAD_INVESTOR_API = `equity/lead-investors/`;
export const REVENUE_API = `admin/revenue-setting/front`;
export const COUNTRIES_API = `admin/manage-options/countries`;
export const RESEND_EMAIL_API = `equity/team-members/resend-email/`;
export const CAMPAIGN_SETTINGS_API = `admin/campaign-setting/front`;
export const MY_INVESTMENTS_API = `my-investment/`;
export const MY_INVESTMENTS_DASHBOARD_API = `my-investment/dashboard/`;
export const MY_INVESTMENTS_GRAPH_API = `my-investment/get-graph-data`;
export const MY_INVESTMENTS_COLUMN_CHART_API = `my-investment/get-graph-by-transactions`;
export const CURRENCY_API = `admin/currencies/front`;
export const LATEST_INVESTMENTS_API = `process-investment/get-latest-investment-activity/front`;
export const STATISTICS_API = `users/get-investment-received-pending-investments`;
export const SOCIAL_LOGIN = `users/social-login`;
export const USER_INVITATION_API = `users/send-invites`;
export const USER_PROFILE_CAMAPAIGNS_API = "users/get-user-campaigns/";
export const USER_PROFILE_INVESTORS_API = "users/get-user-investments/";
export const USER_INVESTMENT_UNIQUE_CURRENCIES_API =
	"my-investment/get-unique-currency";
export const CONTACT_US_API = `contact-us`;
export const PAYPAL_GENERATE_TOKEN_API = `equity/paypal-payment/checkout-session`;
export const PAYPAL_CONFIRM_PAYMENT_API = `equity/paypal-payment/process-payment`;
export const GET_CAMPAIGN_INVESTORS_API = `process-investment/campaign-investors`;
export const VERIFY_TWO_FACTOR_USER_API = `users/validateSignupTwoFactorUser`;
export const VERIFY_TWO_FACTOR_LOGIN_API = `users/validateLoginTwoFactorUser`;
export const USER_TYPE_API = `admin/usertypes/`;
export const USER_STRIPE_CONNECTION_URL = `equity/ach-payment/get-connection-url`;
export const USER_STRIPE_ACH_CONNECTED_API = `equity/ach-payment/connect-ach-account`;
export const USER_STRIPE_ACH_GET_ALL_USER_ACCOUNTS_API = `equity/ach-payment/get-all-accounts-by-user`;
export const USER_STRIPE_ACH_DISCONNECTED_API = `equity/ach-payment/disconnect-ach-account/`;
export const GET_TWO_FACTOR_QR_CODE = `users/regenerate-qr-code`;

export const STRIPE_ACH_PAYMENT_TOKEN_API = `equity/ach-payment/create-payment-link-token`;
export const STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API = `equity/ach-payment/process-payment`;

export const OMAN_PAYMOB_PAYMENT_TOKEN_API = `equity/paymob-payment/get-wallet-checkout-url`;

export const CAMPAIGN_ACH_SETTINGS = `admin/payment-gateway/ach/front?fields=ownerPayoutEnable,achAutoRepayment,repaymentStatus,achSupport,status`;
export const SUBSCRIBE_NEWSLETTER = `users/current-user-newsletter-subscribe`;
export const RESEND_EMAIL_VERIFICATION_API = `users/resendUserActivationLink`;

export const SOCIAL_SETTING_API = `admin/social-signup/`;

export const ADD_WALLET_TOP_UP_API = `wallet/add-wallet-topup`;
export const WALLET_ACH_PAYMENT_API = `/equity/ach-payment/wallet-process-payment`;
export const GET_WALLET_DETAILS_API = `wallet/get-user-wallet-detail`;
export const GET_WALLET_TRANSACTIONS_LIST_API = `wallet/get-user-wallet-list`;
export const GET_WALLET_PREAPPROVAL_DETAILS_API = `wallet/wallet-preapproval`;
export const WALLET_OFFLINE_PAYMENT_API = `wallet/update-wallet-topup`;
export const WALLET_STRIPE_PAYMENT_API = `equity/payment-gateway/wallet-checkout-session`;
export const WITHDRAW_FROM_WALLET_API = `wallet/wallet-withdraw`;
export const HOME_SECTION_API = `admin/content-page/home-page-section/front`;
export const TESTIMONIALS_API = `admin/testimonials/front`;

// Investor Profile Document upload
export const INVESTOR_PROFILE_DOCUMENTS_API = `investor-profile-documents/`;

// Manual KYC
export const USERS_APPLY_MANUAL_KYC = `users/apply-manual-kyc`;
export const BANKS_API = `admin/banks`;
export const USER_BANK_ACCOUNTS_API = `user-bank-accounts`;
export const OPEN_AWS_FILE_API = `equities/get-signed-url/front`;

export const BECOME_EARLY_BIRD_API = `equities/become-early-bird`;
export const GENERATE_DYNAMIC_CONTRACT_API = `contracts/generate-dynamic-contract-data`;
export const DELETE_DYNAMIC_CONTRACT_API = `contracts/delete-pdf`;
export const GET_RESIGN_CONTRACT_LIST_API = `my-investment/get-re-sign-contract-list`;

export const GET_SOURCES_OF_INCOME_TYPE_API = `admin/sources-of-income-type/sub-type/`;
export const GET_EMPLOYMENT_STATUS_LIST_API = `admin/employment-status`;
export const GET_EMPLOYMENT_CATEGORY_LIST_API = `admin/employment-category`;
export const GET_EMPLOYMENT_OCCUPATION_CATEGORY_LIST_API = `admin/employment-category/occupation/`;

export const GET_MASTER_DROPDOWN_API = `admin/manage-options/`;
export const GET_SUITABILITY_TEST_API = `admin/suitability-test`;

// OTP Verification API
export const POST_RESEND_OTP_API = `users/resend-otp`;
export const POST_VERIFY_OTP_API = `users/verify-otp`;
export const CHANGE_ACCOUNT_PHONENUMBER_API = `users/change-number`;

export const USER_ACCOUNT_KYC_API = `account-verification`;
export const PATCH_USER_ACCOUNT_KYC_API = `account-verification/complete-verification`;
export const PATCH_ACCOUNT_RESEND_OTP_API = `account-verification/resend-otp`;
export const PATCH_ACCOUNT_VERIFY_OTP_API = `account-verification/verify-otp`;
export const USER_ACCOUNT_AGREEMENT_API = `account-verification/generate-dynamic-contract-data`;

// Children Registration API
export const CHILDREN_REGISTRATION_API = `child-account-verification`;
export const CHILDREN_REGISTRATION_DETAIL_API = `child-account-verification/`;
export const CHILDREN_REGISTRATION_AGREEMENT_API = `child-account-verification/generate-dynamic-contract-data`;
export const PATCH_CHILD_RESEND_OTP_API = `child-account-verification/resend-otp`;
export const PATCH_CHILD_VERIFY_OTP_API = `child-account-verification/verify-otp`;

// Children Registration API
export const WALLET_CHILD_USER_LIST_API = `wallet/get-child-wallet-list`;
export const WALLET_CHILD_TRANSACTIONS_API = `wallet/get-child-wallet-transaction-list/`;
export const ADD_CHILD_WALLET_TOP_UP_API = `wallet/add-wallet-topup/child/`;
export const CHILD_WITHDRAW_FROM_WALLET_API = `wallet/child-wallet-withdraw/`;
export const CHILDREN_ACCOUNT_SETTINGS_API = `admin/child-account-setting/front`;
export const GET_CHILD_WALLET_DETAILS_API = `wallet/get-child-wallet-detail/`;
export const GET_CHILD_INVESTMENTS_API = `my-investment/child-dashboard/`;
export const GET_CHILD_INVESTMENT_LIST_API = `my-investment/child-investment/`;
export const GET_GUARDIAN_OMAN_ID_SEARCH_API = `child-account-verification/get-other-guardian-ekyc-data`;
export const VERIFY_MFA_API = `users/validateLoginTwoFactorUser`;
