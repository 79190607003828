export const getURL = () => {
	const url = document.location.href;
	return url.substring(url.lastIndexOf("/") + 1)?.split("?")[0];
};

export const getBaseURL = () => {
	const url = process.env.REACT_APP_BASE_URL;
	return url;
};

export const getExternalURL = () => {
	const url = process.env.REACT_APP_EXTERNAL_URL;
	return url;
};

export const getBaseImageURL = () => {
	const url = process.env.REACT_APP_AWS_URL;
	return url;
};

export const getContractAWSURL = () => {
	const url = process.env.REACT_APP_AWS_S3_URL;
	return url;
};
